<template>
  <div>
    <el-card class="box-card">
      <div
        slot="header"
        class="clearfix"
      >
        <span>Area Wise Report</span>
        <el-button
          type="success"
          size="mini"
          class="ml-3"
        >
          Total {{ reportData.items ? reportData.items.length:0 }}
        </el-button>
        <el-form
          :inline="true"
          :model="formData"
          class="float-right"
        >
          <el-form-item label="Year">
            <el-select
              v-model="formData.year"
              placeholder="Select Year"
            >
              <el-option
                v-for="year in yearOptions"
                :key="year"
                :label="year"
                :value="year"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Area">
            <el-select
              v-model="formData.area_id"
              placeholder="Select Area"
            >
              <el-option
                v-for="item in areas"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="getReport"
            >
              Generate
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="report">
        <div class="table-container">
          <table
            v-if="reportData.items.length"
            class="table"
          >
            <thead class="">
              <tr>
                <th
                  v-for="col in reportData.columns"
                  :key="col"
                >
                  {{ col }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="col in reportData.columns"
                  :key="col"
                  :class="['M/Bill','JAN','FEB','MAR','APR','MAY','JUNE','JULY','AUG','SEP','OCT','NOV','DEC'].includes(col) ? 'bg-green' :''"
                >
                  <span v-if="col==='M/Bill'">
                    {{ getSum('m_bill') }}
                  </span>
                  <span v-if="col==='JAN'">
                    {{ getSum('JAN') }}
                  </span>
                  <span v-if="col==='FEB'">
                    {{ getSum('FEB') }}
                  </span>
                  <span v-if="col==='MAR'">
                    {{ getSum('MAR') }}
                  </span>
                  <span v-if="col==='APR'">
                    {{ getSum('APR') }}
                  </span>
                  <span v-if="col==='MAY'">
                    {{ getSum('MAY') }}
                  </span>
                  <span v-if="col==='JUNE'">
                    {{ getSum('JUNE') }}
                  </span>
                  <span v-if="col==='JULY'">
                    {{ getSum('JULY') }}
                  </span>
                  <span v-if="col==='AUG'">
                    {{ getSum('AUG') }}
                  </span>
                  <span v-if="col==='SEP'">
                    {{ getSum('SEP') }}
                  </span>
                  <span v-if="col==='OCT'">
                    {{ getSum('OCT') }}
                  </span>
                  <span v-if="col==='NOV'">
                    {{ getSum('NOV') }}
                  </span>
                  <span v-if="col==='DEC'">
                    {{ getSum('DEC') }}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, rowIndex) in reportData.items"
                :key="rowIndex"
                :class="{ 'expanded-row': expandedRows.includes(rowIndex) }"
                @click="toggleRow(rowIndex)"
              >
                <template v-if="row.months.length > 0">
                  <td
                    v-for="(month, monthName) in row.months"
                    :key="monthName"
                  >
                    <div class="cell-content">
                      {{ month }}
                    </div>
                  </td>
                </template>
                <template v-else>
                  <td
                    v-for="(cell, colIndex) in getFilteredRow(row,'cell')"
                    :key="colIndex"
                    :style="{
                      maxWidth: clickedCell.row === rowIndex && clickedCell.col === colIndex ? '100%' : '160px',
                      textOverflow: clickedCell.row === rowIndex && clickedCell.col === colIndex ? 'unset' : 'ellipsis'
                    }"
                    @click="toggleCell(rowIndex, colIndex)"
                  >
                    <div
                      class="cell-content"
                    >
                      {{ cell }}
                    </div>
                  </td>
                  <td
                    v-for="(cell, colIndex) in getFilteredRow(row,'months')"
                    :key="colIndex"
                    :style="{
                      maxWidth: clickedCell.row === rowIndex && clickedCell.col === colIndex ? '100%' : '160px',
                      textOverflow: clickedCell.row === rowIndex && clickedCell.col === colIndex ? 'unset' : 'ellipsis'
                    }"
                    @click="toggleCell(rowIndex, colIndex)"
                  >
                    <div
                      class="cell-content"
                      :class="cell.status===1?'bg-green br-5':'bg-red'"
                    >
                      {{ cell.amount }}
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="no-items"
          >
            <img
              src="@/assets/images/svg/void.svg"
              alt=""
            >
            <h3 class="mt-3">
              No Result found!
            </h3>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>

export default {
  name: 'AreaWiseReport',
  data() {
    return {
      formData: {
        year: new Date().getFullYear(), // Default to current year
        area_id: null,
      },
      yearOptions: this.generateYearOptions(),
      isp_id: JSON.parse(localStorage.getItem('userData')),
      areas: [],
      reportData: {
        columns: [
          'Name',
          'Address',
          'Email',
          'Contact No.',
          'Username',
          'Speed',
          'Reg. Date',
          'Act.',
          'M/Bill',
          'JAN',
          'FEB',
          'MAR',
          'APR',
          'MAY',
          'JUNE',
          'JULY',
          'AUG',
          'SEP',
          'OCT',
          'NOV',
          'DEC',
        ],
        items: [
        ],
      },
      clickedCell: { row: null, col: null },
      expandedRows: [],
    }
  },
  mounted() {
    this.getAreaList()
    // this.getReport()
  },
  methods: {
    getAreaList() {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL}/api/areas?isp_id=${this.isp_id.id}`)
        .then(res => {
          this.areas = res.data.data
        })
    },
    getReport() {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL}/api/area-wise-report?isp_id=${this.isp_id.id}&area_id=${this.formData.area_id}&year=${this.formData.year}`)
        .then(res => {
          console.log('res.data.data', res.data.data)
          this.reportData.items = res.data.data
        })
    },
    generateYearOptions() {
      const currentYear = new Date().getFullYear()
      const startYear = 2015 // You can adjust this to your needs
      const years = []

      // eslint-disable-next-line no-plusplus
      for (let year = currentYear; year >= startYear; year--) {
        years.push(year)
      }

      return years
    },
    toggleRow(index) {
      const rowIndex = this.expandedRows.indexOf(index)
      console.log('rowindex', rowIndex)
      if (rowIndex === -1) {
        this.expandedRows.push(index)
      } else {
        this.expandedRows.splice(rowIndex, 1)
      }
    },
    toggleCell(row, col) {
      if (this.clickedCell.row === row && this.clickedCell.col === col) {
        this.clickedCell = { row: null, col: null } // Collapse if clicked again
      } else {
        this.clickedCell = { row, col } // Expand specific cell
      }
    },
    getFilteredRow(row, type) {
      if (type === 'months') {
        return row.months ? row.months : row
      }
      const { months, ...filteredRow } = row // Destructure to remove months
      return filteredRow // Return the object without the months key
    },
    getSum(col) {
      if (col === 'm_bill') {
        return this.reportData.items.reduce((sum, row) => sum + (row.m_bill || 0), 0)
      } if (Object.keys(this.reportData.items[0]?.months || {}).includes(col)) {
        // Sum for months
        return this.reportData.items.reduce((sum, row) => {
          const monthData = row.months[col]
          let total = (typeof monthData === 'object' ? (monthData.amount || 0) : monthData || 0)
          if (monthData.status !== 1) {
            total = 0
          }
          return sum + total
        }, 0)
      }
      return ''
    },

  },
}
</script>

<style scoped>
.report{
  text-align: center;
}
.table {
  font-size: 14px;
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
  display: inline;
}
.table th,
.table td {
  padding: 6px 3px;
  text-align: left;
  border: 1px solid #ddd;
  white-space: nowrap;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}
.table .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table .truncate:hover {
  cursor: pointer;
}
.expanded-row {
  background-color: #f9f9f9;
}
.expanded-row .truncate1{
  background-color: #c3bebe;
  text-overflow: inherit;
  max-width: 100%;
}
.expanded .cell-content {
  white-space: normal;
  overflow: visible;
}
.no-items img{
  width: 130px;
}
.bg-green{
  background: #68eb0e !important;
  color: #000;
  text-align: center !important;
}
.bg-red{
  background: red !important;
  color: #000;
  text-align: center !important;
}
.br-5{
  border-radius: 5px;
}
.report {
  text-align: center;
}

.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
}

.table {
  font-size: 14px;
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 6px 3px;
  text-align: left;
  border: 1px solid #ddd;
  white-space: nowrap;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
}

.table .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .truncate:hover {
  cursor: pointer;
}

.no-items img {
  width: 130px;
}

/* Add responsiveness */
@media (max-width: 768px) {
  .table {
    font-size: 12px;
  }

  .table th,
  .table td {
    padding: 4px;
  }

  .el-form {
    flex-wrap: wrap;
  }

  .el-form-item {
    width: 100%;
  }

  .el-button {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .table {
    font-size: 10px;
  }

  .table th,
  .table td {
    padding: 3px;
  }

  .el-form-item {
    width: 100%;
    margin-bottom: 5px;
  }

  .el-form-item .el-select {
    width: 100%;
  }

  .no-items img {
    width: 100px;
  }
}

</style>
